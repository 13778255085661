import { Controller } from "@hotwired/stimulus"
import("select2").then(m => m.default());


// Connects to data-controller="leagues--players"
export default class extends Controller {


  static values = { box: Number, leaguetableid: Number, clubid: Number, leagueid: Number }
  static targets = [ 'selectbox' ]
  
  connect() {

    var leaguetableid = this.leaguetableidValue
    var boxNumber = this.boxValue
    var clubid = this.clubidValue
    var leagueid = this.leagueidValue
    var select = this.selectboxTarget
    const authenticityToken = $('meta[name=csrf-token]').attr('content')
   
    console.log(`#player-choose-box-${boxNumber}`)

    document.addEventListener('turbo:load', function () {
      $(select).select2({
        selectOnClose: true,
        ajax: {
          url: `/clubs/${clubid}/leagues/${leagueid}/available_players.json`,
          dataType: 'json'
        }
      });
    
      $(select).on('select2:select', function (e) {
        var player_data = e.params.data;
        console.log('data', player_data);
        
        var data = {
          'user_id': player_data.id,
          'league_table_id': leaguetableid
        }
   
     
        var url = `/clubs/${clubid}/leagues/${leagueid}/add_user_to_league_table.json`
        $.ajax({
          type: "POST",
          url: url,
          headers: { 'X-CSRF-Token': authenticityToken },
          dataType: 'json',
          data: data,
          success: (d) => {
            location.reload()
            console.log('SUCCESS')
          }
        })
  











       

      });
    
    
    
    
    });




  }



}

//?current_users=<%= @tournaments_grade.player_ids.join(",")%>