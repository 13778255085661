import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { credits: Number }

  connect() {
   // console.log('Clive Was Here HELLO');
 //   this.element.textContent = "Hello World!"
  }


  credits(){
    $('#wallet_transaction_credits').val(this.creditsValue)
  }

}
