import "datatables.net";
import DataTable from "datatables.net-bs5";

// import DataTable from 'jquery';
 window.DataTable = DataTable();



import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons-bs5";
import "datatables.net-responsive";
import "datatables.net-select";
import "datatables.net-fixedheader";
