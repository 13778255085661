import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="court-booking-name-change"
export default class extends Controller {

  static targets = [ "userid", 'name', 'newname', 'savebutton' ]
  
  connect() {

  }

  change_name(){

    var userid = this.useridTarget.value
    var nameselect = this.nameTarget
    var name = this.nameTarget.value
    var newname = this.newnameTarget.value
    var newnamefield = this.newnameTarget
    var data = {
      user_id: userid,
      name: name,
      new_name: newname
    }

    const authenticityToken = $('meta[name=csrf-token]').attr('content')
   
    var url = '/api/v1/user/court_booking_name'
    $.ajax({
      type: "POST",
      url: url,
      headers: { 'X-CSRF-Token': authenticityToken },
      dataType: 'json',
      data: data,
      success: (d) => {
  
        if (d.newname){

          var option = document.createElement("option");
          option.text = d.newname;
          nameselect.add(option, 1);
          nameselect.selectedIndex=1
          newnamefield.value = ''


        }
      }
    })
  }

  delete_name(event){

    var button = event.target
   
    var userid = this.useridTarget.value
    var remove_name = button.getAttribute("data-nname")
    var data = {
      user_id: userid,
      name: remove_name
    }
    const authenticityToken = $('meta[name=csrf-token]').attr('content')
   
    var url = '/api/v1/user/delete_court_booking_name'
    $.ajax({
      type: "POST",
      url: url,
      headers: { 'X-CSRF-Token': authenticityToken },
      dataType: 'json',
      data: data,
      success: (d) => {
        button.remove();     
      }
    })


  }


  enable_button(){
    this.savebuttonTarget.disabled = false;
    console.log('Enabling')
  }

}
