import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scoring--charts"
export default class extends Controller {

  // static values = {targetcanvas: String, title: String, player1: String, player2: String, player1scores: Array, player2scores: Array, scoreslabels: Array, winners: Array, strokes: Array, errors: Array }
  static values = {
    targetcanvas: String, 
    title: String, 
    player1: String, 
    player2: String, 
    player1scores: Array, 
    player2scores: Array, 
    scoreslabels: Array,
    winnerpoints: Array,
    strokepoints: Array,
    errorpoints: Array,
  }


  connect() {



    var title = this.titleValue
    var targetcanvas = this.targetcanvasValue
    var player1 = this.player1Value
    var player2 = this.player2Value

    var winnerpoints = this.winnerpointsValue
    var errorpoints = this.errorpointsValue
    var strokepoints = this.strokepointsValue


    var player1scores = this.player1scoresValue
    var player2scores = this.player2scoresValue
    var scoreslabels = this.scoreslabelsValue


    var options =  {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'TEXT'
        }
      }
    }

    var data = {
      labels: scoreslabels,
      datasets: [{
        label: player1,
        data: player1scores,
        borderColor: 'rgb(75, 192, 192)',
        fill: false,
        borderWidth: 2,
        tension: 0.1,

      },
      {
        label: player2,
        data: player2scores,
        borderColor: 'rgb(255, 0, 255)',
        fill: false,
        borderWidth: 2,
        tension: 0.1
      },
      {
        label: "Stroke",
        data: strokepoints,
        borderColor: 'rgb(255, 0, 0)',
        fill: false,
        borderWidth: 10,
        tension: 0.1,
         type: 'scatter',
         pointStyle: 'crossRot',
         pointRadius: 10
      },
      {
        label: "Winner",
        data: winnerpoints,
        borderColor: 'rgb(10, 205, 150)',
        fill: false,
        borderWidth: 5,
        tension: 0.1,
         type: 'scatter',
         pointStyle: 'star',
         pointRadius: 10
      },
      {
        label: "Error",
        data: errorpoints,
        borderColor: 'rgb(255, 200, 100)',
        fill: false,
        borderWidth: 10,
        tension: 0.1,
         type: 'scatter',
         pointStyle: 'rectRounded',
         pointRadius: 10
      }
      ]
    }


    // var config = {
    //   type: 'line',
    //   data: data, 
    //   options: options,

    // }


    const config = {
      type: 'line',
      data: data,
      options: {
          title: {
            display: true,
            text: title,
          }
      }
    };



    const ctx = document.getElementById(targetcanvas);
    console.log('ctx', ctx)
    new Chart(ctx, config);


  }
}
