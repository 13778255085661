import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf";

export default class extends Controller {



    static values = { dateIndex: String, clubId: Number,  courtId: Number,  userId: Number, date: String }
    static targets = [ 'eventid' ]

    remove(event){
        var url = `/clubs/${this.clubIdValue}/booking/court/${this.courtIdValue}/court_event/${this.eventidTarget.value}/remove_ajax`
       // console.log(url);
        var data = {
            'user_id': this.userIdValue,
            'start':   this.dateValue,
            'element': `${this.dateIndexValue}-${this.courtIdValue}`,
            'eventId': this.eventidTarget.value        
        }
        $.post( url, data, function( data ) {
            $("#"+data.element).removeClass('bg-info');
            $('#controller-remove-'+ data.element).val(data.event_id).hide();
            $('#controller-add-'+ data.element).val(data.event_id).show();
            notyf.success("Booking Removed")
        }).fail(function(xhr, textStatus, errorThrown) {
            window.location = '/';
        });;
    }


    add(event) {
        event.preventDefault()
        var data = {
            'user_id': this.userIdValue,
            'start':   this.dateValue,
            'element': `${this.dateIndexValue}-${this.courtIdValue}`
        }
       // console.log(data)
        var url = `/clubs/${this.clubIdValue}/booking/court/${this.courtIdValue}/court_event/add_ajax`

        $.post( url, data, function( data ) {
            if(!data.error){
              //  console.log(data)
                $("#"+data.element).addClass('bg-info');
                var control_ele = '#controller-remove-'+ data.element
                $('#controller-remove-event-id-'+ data.element).val(data.event_id);
                $('#controller-remove-'+ data.element).val(data.event_id).show();
                $('#controller-add-'+ data.element).val(data.event_id).hide();
                notyf.success("Booking Added")
            }else{
                notyf.error("Cannot add booking as it has been taken. Please refresh your page.")
            }
            }).fail(function(xhr, textStatus, errorThrown) {
                window.location = '/';
            });
    }

}
