import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clubnight-timing"
export default class extends Controller {
  static targets = [ "output", "header"]
  static values = { startdate: String, enddate: String, clubid: Number , clubnightid: Number }


  connect() {
    // this.outputTarget.textContent = 'Clive was Here'
    // console.log('Clubnight Timing')
    this.load()
    this.startRefreshing()
  }

  
  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, 1000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
  load(){
    this.headerTarget.textContent = "Starting"
    var new_time = ''
    var startdate = this.startdateValue
    var enddate = this.enddateValue
    var clubid = this.clubidValue
    var clubnightid = this.clubnightidValue
   
    var countDownDate = new Date(startdate).getTime();
    var finishDate = new Date(enddate).getTime();
   
    // console.log("countDownDate:", countDownDate, " startdate: ", startdate, 'clubid:', clubid, ' clubnightid: ', clubnightid)
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60 ));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (days > 0){
      new_time += days + 'd ' 
    }
      new_time += hours +'h '
      new_time += minutes + "m " + seconds + "s ";
     
     if (distance < 0) {
      //console.log('finishDate', finishDate, 'now', now)
       this.headerTarget.textContent = ""
         $(".delete-player").hide();
         new_time = "Clubnight Running"

         if (finishDate < now){
          new_time = "Clubnight Finished"
          $('#header-status').removeClass('bg-primary').addClass('bg-danger')
          $("#generation-status").hide()
          this.stopRefreshing()
         }
     }
   


    this.outputTarget.textContent = new_time
  }

}
