import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clubnight-session"
export default class extends Controller {
  static targets = [ "time", 'box', "output", 'clubid' , 'clubnightid']
 
  connect() {  
    // console.log('Clubnight Player Connected')
   
  }

  
  nextSession(){
 
    var url = `/clubs/${this.clubidTarget.value}/clubnight/club_nights/${this.clubnightidTarget.value}/plan/${this.timeTarget.value}.json`
    
    console.log(url)


    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }});
    var data = {
      url: url,
      time: this.timeTarget.value
    }
     console.log('nextSession::data: ', data)
    

    $.post( url, data, function( data ) {
      notyf.success(data.data)
     }).fail(function(xhr, textStatus, errorThrown) {
      notyf.error(`${textStatus}: ${errorThrown}`)
     });



    
  }



}
