import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf";

export default class extends Controller {



    static values = {clubId: Number, bookingSlotId: Number,  courtId: Number,  userId: Number, date: String, signature: String }
    // static targets = [ 'bookingevent' ]



    book(event){

        var data = {
            'user_id': this.userIdValue,
            'date':   this.dateValue,
            'court_booking_slot_id': this.bookingSlotIdValue,
            'court_id': this.courtIdValue,
            'element': `controller-bookingv2-${this.bookingSlotIdValue}`,
        }


        console.log("data", data)
        var url = `/clubs/${this.clubIdValue}/booking_v2/book/${this.signatureValue}.js`
        console.log(url)

        $.post( url, data, function( return_data ) {
         

            if (!return_data.error){
                $('#'+ data.element).addClass('bg-info').removeClass('text-dark').addClass('text-light');;
                $('#'+ data.element+'-booking-name').html(return_data.name_on_booking);
                $('#'+ data.element+'-booking-name').show();
                $('#'+ data.element+'-book').hide();
                $('#'+ data.element+'-cancel').show();
                $('#'+ data.element+'-booking-detail').hide();
                $('#client-credits').html(return_data.credits);
                notyf.success(return_data.message)
            } else {
                notyf.error(return_data.message)
            }


        }).fail(function(xhr, textStatus, errorThrown) {
        //    window.location = '/';
        });
    }


    cancel(event){

        var data = {
            'user_id': this.userIdValue,
            'date':   this.dateValue,
            'start':   this.startValue,
            'court_booking_slot_id': this.bookingSlotIdValue,
            'court_id': this.courtIdValue,
            'element': `controller-bookingv2-${this.bookingSlotIdValue}`,
        }



    

        console.log("data", data)
        var url = `/clubs/${this.clubIdValue}/booking_v2/cancel/${this.signatureValue}.js`
          console.log(url)

        $.post( url, data, function( return_data ) {
         
     
        
            if (!return_data.error){
                $('#'+ data.element).removeClass('bg-info').removeClass('text-light').addClass('text-dark');
                $('#'+ data.element+'-booking-name').html('');
                $('#'+ data.element+'-booking-name').hide();
                $('#'+ data.element+'-book').show();
                $('#'+ data.element+'-cancel').hide();
                $('#'+ data.element+'-booking-detail').show();
                $('#client-credits').html(return_data.credits);
                console.log('return_data', return_data)
                notyf.success(return_data.message)
            } else {
                notyf.error(return_data.message)
            }

            


        }).fail(function(xhr, textStatus, errorThrown) {
           // window.location = '/';
        });
    }


    // add(event) {
    //     event.preventDefault()
    //     var data = {
    //         'user_id': this.userIdValue,
    //         'start':   this.dateValue,
    //         'element': `${this.dateIndexValue}-${this.courtIdValue}`
    //     }
    //    // console.log(data)
    //     var url = `/clubs/${this.clubIdValue}/booking/court/${this.courtIdValue}/court_event/add_ajax`

    //     $.post( url, data, function( data ) {
    //         if(!data.error){
    //           //  console.log(data)
    //             $("#"+data.element).addClass('bg-info');
    //             var control_ele = '#controller-remove-'+ data.element
    //             $('#controller-remove-event-id-'+ data.element).val(data.event_id);
    //             $('#controller-remove-'+ data.element).val(data.event_id).show();
    //             $('#controller-add-'+ data.element).val(data.event_id).hide();
    //             notyf.success("Booking Added")
    //         }else{
    //             notyf.error("Cannot add booking as it has been taken. Please refresh your page.")
    //         }
    //         }).fail(function(xhr, textStatus, errorThrown) {
    //             window.location = '/';
    //         });
    // }

}
