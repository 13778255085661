import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf";

export default class extends Controller {



    static values = { element: String, clubId: Number, userId: Number, date: String }
    static targets = [ 'availabilityid' ]

    remove(event){
        var url = `/clubs/${this.clubIdValue}/availability/${this.availabilityidTarget.value}/remove_event_ajax`
        // console.log('REMOVING'); 
        // console.log(url);
  
        var data = {
            'user_id': this.userIdValue,
            'start':   this.dateValue,
            'element': `${this.elementValue}`,
            'availability_event_id': this.availabilityidTarget.value        
        } 
             console.log(data);
        $.post( url, data, function( data ) {
             $("#"+data.element).removeClass('bg-info');
             $('#controller-remove-'+ data.element).val(data.event_id).hide();
             $('#controller-add-'+ data.element).val(data.event_id).show();
           notyf.success("Availability Removed")
       }).fail(function(xhr, textStatus, errorThrown) {
        window.location = '/';
    });;
    }


    add(event) {
        event.preventDefault()
        var data = {
            'user_id': this.userIdValue,
            'start':   this.dateValue,
            'element': `${this.elementValue}`
        }
        var url = `/clubs/${this.clubIdValue}/availability/add_event_ajax`
        // console.log('ADDING'); 
        // console.log(url);
        // console.log(data)

        $.post( url, data, function( data ) {
            if(!data.error){
                console.log(data)
                $("#"+data.element).addClass('bg-info');
                var control_ele = '#controller-remove-'+ data.element
                $('#controller-remove-availability-id-'+ data.element).val(data.event_id);
                $('#controller-remove-'+ data.element).val(data.event_id).show();
                $('#controller-add-'+ data.element).val(data.event_id).hide();
                notyf.success("Availability Added")
            }else{
                notyf.error("Cannot add availability. Please refresh your page.")
            }
        }).fail(function(xhr, textStatus, errorThrown) {
            window.location = '/';
        });;
    }

}
