import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notify"
export default class extends Controller {
  static values = { message: String, flashType: String }


  connect() {

    // if (typeof notyf === 'undefined') {
    //   console.log('notyf FOUND')
    // } else {
    //   console.log('notyf NOT FOUND')
    // }

    // document.addEventListener('turbo:load', function () {
      if (this.flashTypeValue == 'notice'){
        notyf.success(this.messageValue);
  
      }
      if(this.flashTypeValue == 'alert'){
        notyf.error(this.messageValue);
      }
      
    // });

  }
}
