import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="live-stream-overlay"
export default class extends Controller {
  connect() {
    setTimeout(function(){

      $('#overlay-message').hide()

    }, 2000)
  }
}
