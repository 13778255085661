import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="squashlevels--search"
export default class extends Controller {


  static values = {currentuserid: Number, token: String, name: String }
  static targets = [ 'result', 'name' ]



  connect() {
    $('#result-table').hide();
   
    console.log("Connected to SquashLevels controller")

  }

  toggleLoading(button, spinner) {
    button.toggleClass('disabled')
    spinner.toggleClass('d-none')
  }

  search(event){


    var token =  this.tokenValue
    var nameTarget = this.nameTarget
    var name = nameTarget.value

    var url = `/api/v1/squashlevels/search?name=${name}`
    var toggleLoading = this.toggleLoading

    var token = this.tokenValue
    var current_user_id = this.currentuseridValue

    const button = $(event.target)
    const spinner = button.children('.spinner-border')

    toggleLoading(button, spinner)

  
    $.ajax({
      type: "GET",
      url: url,
      headers: {
          Authorization: 'Bearer ' + token
      },
      dataType: 'json',
      success: function (return_data, status, xhr) {
        toggleLoading(button, spinner)

        var return_text = '';

        $('#result-table').show();
        $('#results').empty();
          $.each(return_data.data.array, function() {
            $('#results').append(`<tr>
              <td>${this.player}</td>
              <td>${this.club}</td>
              <td>${this.county}</td>
              <td>${this.level}</td>
              <td data-controller="squashlevels--assign"
              data-squashlevels--assign-currentuserid-value=${current_user_id}
              data-squashlevels--assign-squashlevelsid-value=${this.playerid}  
              data-squashlevels--assign-squashlevelsname-value='${this.player}'  
              style='cursor: pointer;'><button  data-action="click->squashlevels--assign#save" class=' btn btn-primary btn-sm'>Choose</button></td>
            </tr>`);
          });


        },
      error: function (xhr, status, error) {
        $('#results').empty();
        $('#results').append(`<tr><td colspan=5>${status}: ${error}</td></tr>`)

      }
    });




  }
}


        //       data-actixxon="click->addresses--create#address"
        //       data-addressxxes--create-token-value='${token}'
        //       data-addresxxses--create-summaryline-value='${this}'
        //       data-addrexxsses--create-autofixed-value='manual'