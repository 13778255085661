import { Controller } from "@hotwired/stimulus"



// Connects to data-controller="counter_interval"
export default class extends Controller {
  static targets = [ "outputinterval" ]
  static values = { startDate: String, warmupNumber: Number }
  connect() {

    this.startRefreshing()
  }
  
  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, 1000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
  load(){
    var new_time = ''
    var start = this.startDateValue
    var countDownDate = new Date(start).getTime();
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    new_time =  "Resume: " + minutes + "m " + seconds + "s ";
    if (distance < 0) {
      this.stopRefreshing()
        new_time = "Resume Play"
    }
    this.outputintervalTarget.textContent = new_time
  }
}
