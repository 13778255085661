import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="squashlevels--assign"
export default class extends Controller {


  static values = {currentuserid: Number, squashlevelsid: Number, squashlevelsname: String }

  connect() {


  }



  toggleLoading(button, spinner) {
    button.toggleClass('disabled')
    spinner.toggleClass('d-none')
  }


save(event){

  var current_user_id = this.currentuseridValue
  var squash_levels_id = this.squashlevelsidValue
  var squash_levels_name = this.squashlevelsnameValue
  var toggleLoading = this.toggleLoading

  const button = $(event.target)
  const spinner = button.children('.spinner-border')

  var token = ''

  var url = `/api/v1/squashlevels/assign`
  $.ajax({
    type: "POST",
    data: {current_user_id: current_user_id, squash_levels_id: squash_levels_id, squash_levels_name: squash_levels_name},
    url: url,
    headers: {
        Authorization: 'Bearer ' + token
    },
    dataType: 'json',
    success: function (return_data, status, xhr) {
      toggleLoading(button, spinner)

      var return_text = '';

      $('#result-table').show();
      $('#results').empty();
      $('#results').append(`<tr><td colspan=5>Squash Level saved</td></tr>`)
      window.location = '/home/squashlevels'

      },
    error: function (xhr, status, error) {
      $('#results').empty();
      $('#results').append(`<tr><td colspan=5>${status}: ${error}</td></tr>`)

    }
  });





}

}
