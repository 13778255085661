import { Controller } from "@hotwired/stimulus"



// Connects to data-controller="counter"
export default class extends Controller {
  static targets = [ "output" ]
  static values = { eventTime: String, component: String }
  connect() {
    //this.startRefreshing()
  }
  
  disconnect() {
    //this.stopRefreshing()
  }

  startRefreshing() {
    //this.refreshTimer = setInterval(() => {
    //  this.load()
    //}, 5000)
  }

  stopRefreshing() {
    //if (this.refreshTimer) {
    //  clearInterval(this.refreshTimer)
    //}
  }
  load(){
    // var new_time = ''
    // var currentDate = new Date();
    // var current = currentDate.getTime()
    // var start = this.eventTimeValue;
    // //console.log('currentDate', currentDate);
    // //console.log('start', start);
    // var event = new Date(start).getTime();
    // var component = this.componentValue

    // if (event-current < 0){
    //   $('.'+component).hide();
    //   this.stopRefreshing();
    // }
  }
}
