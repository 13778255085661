import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clubnight-player"
export default class extends Controller {
  static targets = [ "name", "box", "output", 'clubid' , 'clubnightid', 'clubnightplayerid']
 
  connect() {  
    // console.log('Clubnight Player Connected')
  }

  newplayer(){
    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }});
    var formInput = this.nameTarget
    var boxInput = this.boxTarget
    console.log('Searching: ', formInput.value)
    var data = {
      clubnight_player: {
        name: formInput.value, 
        box: boxInput.value, 
        club_night_id: Number(this.clubnightidTarget.value)
      }
    }
  
    if (formInput.value && boxInput.value){
      var url = `/clubs/${this.clubidTarget.value}/clubnight/club_nights/${this.clubnightidTarget.value}/players`
      $.post( url, data, function( data ) {
        notyf.success("Player Added") 
        formInput.value = ""
        boxInput.value = ""
      }).fail(function(xhr, textStatus, errorThrown) {
        notyf.error(`${textStatus}: ${errorThrown}`)
      });

      } else {
        notyf.error("You need to have a player name and a Box level")
      }
  }    

  deleteplayer(){
    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }});
    var data = {
      clubnight_player: {
        club_night_id: Number(this.clubnightidTarget.value)
      }
    }
    console.log('Delete', data)
    var url = `/clubs/${this.clubidTarget.value}/clubnight/club_nights/${this.clubnightidTarget.value}/players/${this.clubnightplayeridTarget.value}.json`
    
    $.ajax({
      url: url,
      type: 'DELETE',
      success: function(result) {
        notyf.success("Player Added")
      },
      error: function(xhr, textStatus, errorThrown) {
        notyf.error(`${textStatus}: ${errorThrown}`)
      }
    });
    
    
   
  }

  stopPlaying(){
    
    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }});
    var data = {
      clubnight_player: {
        club_night_id: Number(this.clubnightidTarget.value)
      }
    }
    var url = `/clubs/${this.clubidTarget.value}/clubnight/club_nights/${this.clubnightidTarget.value}/players/${this.clubnightplayeridTarget.value}/stop_playing.json`
    $.post( url, data, function( data ) {
      notyf.success("Player Marked as stopped playing")
     }).fail(function(xhr, textStatus, errorThrown) {
      notyf.error(`${textStatus}: ${errorThrown}`)
     });;
  }

  resumePlaying(){
    
    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }});
    var data = {
      clubnight_player: {
        club_night_id: Number(this.clubnightidTarget.value)
      }
    }
    var url = `/clubs/${this.clubidTarget.value}/clubnight/club_nights/${this.clubnightidTarget.value}/players/${this.clubnightplayeridTarget.value}/resume_playing.json`
    $.post( url, data, function( data ) {
      notyf.success("Player Marked as resumed playing")
     }).fail(function(xhr, textStatus, errorThrown) {
      notyf.error(`${textStatus}: ${errorThrown}`)
     });;
  }


}
