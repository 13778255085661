import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clubnight-matchgenerate"
export default class extends Controller {
  static targets = [ "output", "header"]
  static values = { startdate: String, matchtimes:Array, clubid: Number , clubnightid: Number }
  static current_time

  connect() {
    // this.outputTarget.textContent = 'Clive was Here'
    // console.log('Clubnight matchgenerate')
    this.current_time = ''
    this.load()
    this.startRefreshing()
  
  }

  
  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, 1000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
  load(){
    var new_time = ''
    // console.log(`------------- ${this.current_time} --`)
    this.headerTarget.textContent = "Next Matches generated in"
    var now_date = new Date();
    var now = now_date.getTime();

    var matchtimes = this.matchtimesValue

    
    
      var newArr = []
      for (let i = 0; i < matchtimes.length; i++) {
        var distance = matchtimes[i]['distance'] - now
        if (distance > 0){
          newArr.push({'distance': (distance), 'time': matchtimes[i]['time']})
        }
      }

      if (newArr.length > 0){
        var distance = newArr[0]['distance']
        console.log('Next Generate',newArr[0])
        console.log('distance',distance)
        console.log('now_date',now_date)
        console.log('now',now)
        if (this.current_time == ''){
          this.current_time = newArr[0]['time']
        }
  
        // console.log("SETTING CHECK: ",this.current_time, 'vs', newArr[0]['time'])

        if ( this.current_time != newArr[0]['time']){

          var url = `/clubs/${this.clubidValue}/clubnight/club_nights/${this.clubnightidValue}/plan/${this.current_time}.json`      
          $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }});
          var data = {
            url: url,
            time: this.current_time
          }
          $.post( url, data, function( data ) {
            notyf.success(data.data)
           }).fail(function(xhr, textStatus, errorThrown) {
            notyf.error(`${textStatus}: ${errorThrown}`)
           });
      
      


          console.log(`*********GEN *** ${this.current_time} *** ${newArr[0]['time']} **********`)
          console.log('************************************ GENERATE *****************************')
          console.log('************************************ GENERATE *****************************')
          this.current_time = newArr[0]['time']
        }
          
     
          
         

         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60 ));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          if (days > 0){
            new_time += days + 'd ' 
          }
            new_time += hours +'h '
            new_time += minutes + "m " + seconds + "s ";
       }
   
       if (new_time == ''){
        $("#generation-status").hide()
       } else {

    this.outputTarget.textContent = new_time
        console.log('new_time', new_time)

       }
  }

}
