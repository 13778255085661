import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf";

export default class extends Controller {

    static values = { fixtureId: Number, clubId: Number, teamId: Number, playerId: Number }
    // static targets = [ 'eventid' ]

 


    availability(event){
        var url = `/clubs/${this.clubIdValue}/team_squash/${this.teamIdValue}/availability/${this.playerIdValue}/fixture/${this.fixtureIdValue}`
        // var url = `/clubs/${this.clubIdValue}/booking/court/${this.courtIdValue}/court_event/${this.eventidTarget.value}/remove_ajax`
       // console.log(url);
        var data = {     
        }
        $.post( url, data, function( data ) {
            // $("#"+data.element).removeClass('bg-info');
            // $('#controller-remove-'+ data.element).val(data.event_id).hide();
            // $('#controller-add-'+ data.element).val(data.event_id).show();
            notyf.success("Availability Updated")
        }).fail(function(xhr, textStatus, errorThrown) {
            window.location = '/';
        });;
    }



}
