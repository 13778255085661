//  import "fullcalendar/main.js";


 import { Calendar } from '@fullcalendar/core';
 import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
 import interactionPlugin from '@fullcalendar/interaction';
 import momentPlugin from '@fullcalendar/moment'

window.Calendar = Calendar;
window.resourceTimeGridPlugin = resourceTimeGridPlugin;
window.interactionPlugin = interactionPlugin;
window.momentPlugin = momentPlugin;